@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$webadmin-primary: mat.define-palette(mat.$indigo-palette);
$webadmin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$webadmin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$webadmin-theme: mat.define-light-theme($webadmin-primary, $webadmin-accent, $webadmin-warn);
$webadmin-dark-theme: mat.define-dark-theme($webadmin-primary, $webadmin-accent, $webadmin-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes(
  $webadmin-theme
); // this applies the style to legacy materials. It can be removed once there are no legacy materials anymore.
@include mat.all-component-themes($webadmin-theme); // applies the style to all upgraded materials.
@include mat-datepicker-theme(
  $webadmin-theme
); // material theme data structure https://material.angular.io/guide/theming#defining-a-custom-theme

.dark-theme {
  @include mat.all-legacy-component-themes($webadmin-dark-theme);
  @include mat.all-component-themes($webadmin-dark-theme);
  @include mat-datepicker-theme($webadmin-dark-theme);
}
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/**
* There was a nasty outline on elements in focus state. At the time of taking over the project and setting this
* it remains unclear to me where this initially came from.
*/
button:focus,
button:active:focus,
button.active:focus {
  outline: none;
  outline-style: none;
}

.content-button-margin > button {
  margin-right: 8px;
}
.content-button-margin > button:last-child {
  margin-right: 0px;
}

.content-formfield-margin mat-form-field {
  margin-right: 8px;
}
.content-formfield-margin mat-form-field:last-child {
  margin-right: 0px;
}

mat-grid-tile {

  div.mat-grid-tile-content {
    display: block;
    margin: 0 2rem;

    mat-form-field {
      display: block;
    }
  }
}

.pagedtable-container {
  position: relative;
  min-height: 200px;
}

.pagedtable-container .table-container {
  position: relative;
  overflow: auto;
}

.pagedtable-container .loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  justify-content: center;

  mat-spinner {
    margin-top: 2rem;
  }
}

nav > ol.breadcrumb {
  background-color: unset;
  margin-bottom: 0px;
  padding-left: 0px;
}

// TODO #108
// This disables the touch targets (invisible, huge buttons the are now automatically put on a lot of stuff).
// The reason to disable this is that in some cases they overlap each other, making it almost impossible to click certain buttons.
// Touch targets weren't there in Angular 9 and nobody complained about it, so we don't need them now.
// Most likely they were made for phones; on a desktop application it should be no problem pressing a button without them.
// Maybe it's possible to just disable them in collectivepaymentlisting and keep them everywhere else, if that's desired.
.mat-mdc-button-touch-target {
  display: none;
}
